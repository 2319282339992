<template>
  <div>
    <vue-good-table
      :columns="options.columns"
      :rows="options.rows"
      styleClass="vgt-table striped bordered vgt-fontsize"
      :sort-options="{
        enabled: false,
      }"
    >
      >
    </vue-good-table>
  </div>
</template>

<script>

import { VueGoodTable } from 'vue-good-table'


export default {
  components: {
    VueGoodTable
  },

  directives: {
  },

  created()
  {
  },

  methods: {

    updateElement()
    {

    },


  },

  mounted()
  {

    this.$emit('elementLoaded', event)

  },

  beforeDestroy()
  {
    //console.log("beforeDestroy 2");
  },

  data()
  {
    return {
      direction: false,

    };
  },

  props: {
    load: null,
    options: {
      type: Object,
      required: true,
    },
  },
};
</script>


<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss">
.vgt-fontsize {
  font-size: 1rem !important;
}
</style>